<template>
      
        <!--news box-->
                <div class="col-12 col-md-4">
                   <router-link :to="'/news/' + data._id">
                  <div class="card border-0 rounded-0 text-white overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_right-cover-2 small-image">
                        <a href="#">
                          <img
                            :src="apiUrl + data.coverImg"
                            :alt="data.title[getTrad]"
                          />
                        </a>
                      </div>
                      <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                        <!-- category -->
                        <a class="p-1 badge badge-primary rounded-0" href="#">{{data.category.title[getTrad]}}</a>
                        <!--title-->
                        <a href="#">
                          <h2 class="h5 text-white my-1">
                            {{trimWords(ToText(data.title[getTrad]),20)}}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                  </router-link>
                </div>
       
</template>
<script>
export default {
  name: 'NewsCard',
  props: {
      data: {type: Object, default: null},
    },
    data: () => ({
     apiUrl: process.env.VUE_APP_API_URL,
  }),
  computed: {
    getTrad(){
        if(this.data.title.en && this.$i18n.locale == 'en-US') return 'en'
        if(this.data.title.ar && this.$i18n.locale == 'ar-EG') return 'ar'
          return 'fr'
    },
  },
  methods: {
		trimWords(value,number){
      if(!value) return ""
      if(value.split(" ").length < number) return value
			return value.split(" ").splice(0,number).join(" ") + '...';
		},
    ToText(HTML)
    {
        var input = HTML;
        return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    },
  },
}
</script>
<style scoped>

.b-0 {
    bottom: 0;
}
.bg-shadow {
    background: rgba(76, 76, 76, 0);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(179, 171, 171, 0)), color-stop(49%, rgba(48, 48, 48, 0.37)), color-stop(100%, rgba(19, 19, 19, 0.8)));
    background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19, 19, 19, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0 );
}

.top-indicator {
    right: 0;
    top: 1rem;
    bottom: inherit;
    left: inherit;
    margin-right: 1rem;
}
.overflow {
    position: relative;
    overflow: hidden;
}
.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.small-image {
    height: 240px;
    text-align: center;
    background: #eee;
}

.small-image img {
    height: 100%;
    text-align: center;
    -o-object-fit: cover;
    object-fit: cover;
}


</style>
<style lang="scss" scoped>
@import '@/mixins.scss';

a.badge-primary:focus, a.badge-primary:hover{
    background-color:$secondary;
}
</style>