<template>
  <div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 text-center pt-3">
            <h2 class="title pl-1">{{$t('menu.bibs')}}</h2>
          </div>
        </div>
        <div class="row">
          <div v-for="post in displayedData" :key="post._id" class="col-sm-6 col-md-6 col-lg-3">
            <router-link :to="'/bib/' + post._id">
               <book-card :data="post"/>
            </router-link> 
          </div>
        </div>
        <div class="pagination">
          <a v-if="page != 1" @click="page=1">«</a> 
          <a v-if="page != 1" @click="page--">‹</a>
          <a v-for="pageNumber in pages.slice(page-1, page+5)" :class="{selected: page == pageNumber}" :key="pageNumber" @click="page = pageNumber">{{pageNumber}}</a>
          <a v-if="page < pages.length" @click="page++">›</a>
          <a v-if="page < pages.length" @click="page=pages.length">»</a>
        </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
import BookCard from '@/components/BookCard'

export default {
  name: 'Posts',
  components: { BookCard },
  props:['data'],
  data: () => ({
    page: 1,
    perPage: 8,
    pages: [],
  }),
  computed: {
		displayedData () {
			return this.paginate(this.data);
		},
	},
  	watch: {
		data () {
			this.setPages();
		},
	},
  methods:{
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate (data) {
			let page = this.page;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
			return  data.slice(from, to);
		},
  },
  
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
  text-align: left;
}

/* Pagination style */
@mixin transition-hover($x){
  transition: all $x linear;
}

.pagination{
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  
  a{
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;
    
    @include transition-hover(.2s);
    
    &:hover,
    &.selected{
      color: #fff; 
      background-color: $primary; 
      border-color: $primary;
    }
  }
}
</style>
